<template>
	<div style="display: flex; justify-content: center; align-items: center; height: 100%">
		<v-card style="padding: 20px; max-width: 400px">
			<v-form ref="form" @submit.prevent="handleSubmit">
				<div v-if="template && template.configurableBaseImage">
					<VueFileAgent
						multiple
						theme="list"
						deletable
						accept="image/*"
						helpText="Escolha uma imagem de background"
						:errorText="{ type: 'Tipo de ficheiro inválido. Só são aceites imagens' }"
						v-model="baseImages"
						class="imageFile"
					/>
					<br />
				</div>
				<v-autocomplete
					v-if="template && template.type === 'consultants'"
					outlined
					item-value="_id"
					item-text="name"
					label="Consultores"
					v-model="consultants"
					:items="users"
					small-chips
					multiple
				/>
				<div v-if="template">
					<v-textarea
						v-for="(option, index) in template.options.filter(o => o.key === 'extraText')"
						:key="index"
						outlined
						v-model="extraTexts[index]"
						label="Texto Extra"
						rows="1"
					/>
				</div>
				<div style="display: flex; gap: 10px">
					<v-select
						outlined
						item-value="_id"
						item-text="name"
						label="Template"
						v-model="template"
						:items="templates"
						return-object
					/>
					<v-btn icon v-if="template" color="primary" @click="openForm(template._id)" style="top: 10px">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon color="primary" @click="openForm()" style="top: 10px">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
				<v-progress-circular v-if="loading" indeterminate color="primary" />
				<v-btn
					v-else
					:disabled="
						!template ||
						(template.type === 'consultants'
							? !consultants.length
							: !baseImages.length ||
							  extraTexts.length < template.options.filter(o => o.key === 'extraText').length) ||
						loading
					"
					color="primary"
					type="submit"
				>
					Gerar
				</v-btn>
			</v-form>
		</v-card>
		<v-dialog v-model="showForm">
			<TemplateForm :template="selectedTemplate" :onClose="closeForm" />
		</v-dialog>
	</div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import FileSaver from "file-saver";

import TemplateForm from "../components/TemplateForm";

import { getUsers } from "../api/users";
import { getTemplates, generateImages } from "../api/templates";

export default Vue.extend({
	name: "ImageGenerator",
	components: { TemplateForm },
	async created() {
		this.handleGetUsers();
		this.handleGetTemplates();
	},
	data() {
		return {
			loading: false,

			showForm: false,
			selectedTemplate: null,

			templates: [],
			users: [],

			template: "",
			consultants: [],

			baseImages: [],
			extraTexts: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		async handleGetTemplates() {
			const response = await getTemplates();

			if (response.status === 200) {
				this.templates = response.data;
			}
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.users = response.data.users;
			}
		},
		async handleSubmit() {
			this.loading = true;

			const { template, consultants, extraTexts } = this;

			const formData = new FormData();

			formData.append("template", template._id);

			for (const baseImage of this.baseImages) {
				const fileName = baseImage.name(true);

				formData.append("baseImages", baseImage.file);
				formData.append("fileNames", fileName);
			}

			formData.append("extraTexts", extraTexts);
			formData.append("consultants", consultants);

			this.baseImages = [];
			this.extraTexts = [];
			this.consultants = [];

			const response = await generateImages(formData);

			if (response.status === 200) {
				this.openToast({ message: "Imagens geradas com sucesso", color: "green" });

				FileSaver.saveAs(`${process.env.VUE_APP_API_URL}/api/files/download/images.zip`, "images.zip");
			}

			this.loading = false;
		},
		openForm(item) {
			if (item) {
				this.selectedTemplate = this.templates.find(t => t._id === item);
			} else {
				this.selectedTemplate = null;
			}

			this.showForm = true;
		},
		closeForm() {
			this.handleGetTemplates();

			this.showForm = false;
		},
	},
});
</script>
